import {Link} from "react-router-dom";
import React, {useContext} from "react";
import style from "./CartCard.module.scss"
import CartItemRow from "./CartItemRow"
import DeliveryTypePopUp from "../popups/DeliveryTypePopUp"
import PaymentMethodPopUp from "../popups/PaymentMethodPopUp"
import TextPopUp from "../popups/TextPopUp"
import {getPaymentUrl} from "../../actions/payments"
import {submitCart} from "../../actions/cart"
import {toast} from "react-toastify";
import {StoreContext} from "../../actions/stores";

const CartCard = ({cart, updateCarts}) => {
    const store = useContext(StoreContext)

    function checkout (){
        if (cart.delivery_type === null){
             toast.error("Por favor, elige una forma de entrega")
            return null
        }
        if (cart.payment_method === null){
             toast.error("Por favor, elige una forma de pago")
            return null
        }

        if(cart.payment_method.code === "mercado_pago"){
            getPaymentUrl(
                cart.seller.uuid,
                cart.uuid,
                store?.uuid,
                (data) => {window.location = data.url}
            )
        } else {
            submitCart(
                cart.uuid,
                () => {window.location.href = "/gracias/"}
            )
        }
    }

    return (
        <div className="col-12 mt-5">
            <div className="box is__big space-y-20" >
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center space-x-15">
                        <div className="avatars space-x-10">
                            <div className="media">
                                { cart.store ?
                                    <img
                                        src={cart.store?.logo}
                                        alt="logo"
                                        className="avatar"
                                    /> : null
                                }
                            </div>
                            <div className="text-center">
                                <Link to="profile">
                                    <h2 className="avatars_name color_brand">
                                        {cart.store?.name || cart.seller.name}
                                    </h2>
                                </Link>
                            </div>
                        </div>
                        {/*<div className="category">*/}
                        {/*    <p className="color_black">*/}
                        {/*        <span className="color_text">Ver medios de contacto</span>*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </div>
                    <div>
                        {/*<div className="avatars space-x-3">*/}
                        {/*    <div className="-space-x-20">*/}
                        {/*        Borrar Carrito*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    {
                        store? null :
                        (<button  
                        className={style.gs_button_right}
                        onClick={() => {window.location.href=`/stash?seller=${cart.seller.uuid}`}}>
                        Más productos del vendedor
                        </button>)   
                    }
                </div>
                <div >
                    {
                        cart.store && cart.store.policies.length > 0 ?
                        (<div className="space-y-20">
                            { cart.store.policies.map(policy => (
                                <TextPopUp linkText={policy.name} text={policy.text}/>
                            ))}
                        </div>) : null
                    }
                    <div className="space-y-20">
                        {cart.items.map((item, i) => (
                            <CartItemRow
                                isActive={cart.status === "active"}
                                item={item} updateCarts={updateCarts} key={i}/>
                        ))}
                        <div className={"row"}>
                            <p className="text-right color_black">Total de Productos:
                                <span className="color_green"> {cart.products_price}</span>
                            </p>
                        </div>

                        <div className="hr"/>

                        <div className="d-flex justify-content-between flex-wrap">
                            <p>
                                <span>
                                    Forma de entrega: <b>{cart.delivery_type ? cart.delivery_type.display : null}</b>
                                </span>
                                <span className="float-right">Costo Envio:
                                    <span className="color_green"> {cart.shipping_price}</span>
                                </span>
                            </p>
                        </div>
                        <div className="d-flex justify-content-center">
                            {
                                cart.status === "active" ? (
                                    <DeliveryTypePopUp sellerId={cart.seller.uuid} cartId={cart.uuid}
                                           updateCarts={updateCarts} deliveryType={cart.delivery_type?.code}/>
                                ) : null
                            }

                        </div>


                        <div className="hr"/>

                        <div className="d-flex justify-content-between flex-wrap">
                            <p>
                                <span>Método de pago: <b>{cart.payment_method ? cart.payment_method.display : null}</b></span>
                                <span className="float-right">Comision por Método de Pago:
                                    <span className="color_green"> {cart.payment_fee}</span>
                                </span>
                            </p>
                        </div>
                        <div className="d-flex justify-content-center">
                            {
                                cart.status === "active" ? (
                                    <PaymentMethodPopUp sellerId={cart.seller.uuid} cartId={cart.uuid}
                                                    updateCarts={updateCarts} paymentMethod={cart.payment_method?.code}/>
                                    ) : null
                            }
                        </div>

                        <div className="hr"/>

                        <h3 className="text-center">
                            Total a pagar: <span className="color_green"> {cart.total_price}</span>
                        </h3>
                        {
                            cart.status === "active" ? (
                                <div className="d-flex justify-content-center">
                                    <button className={style.gs_button} onClick={checkout}>
                                        Realizar Compra
                                    </button>
                                </div>
                            ) : null
                        }

                    </div>
                </div>
            </div>


        </div>
    )
}

export default CartCard;