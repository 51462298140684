import Popup from "reactjs-popup";
import React, {useRef} from "react";
import WideButton2 from "../buttons/WideButton2";
import style from "./SellersPopUp.module.scss"

function TextPopUp({linkText, text}){
    const ref = useRef();
    const closeTooltip = () => ref.current.close();
    return (
        <Popup
            className="custom"
            ref={ref}
            trigger={
                <div role="button" tabIndex="0">{linkText}</div>
            }
            position="bottom center"
            modal
            lockScroll
        >
            <div>
                <div
                    className="popup"
                    id="popup_bid"
                    tabIndex={-1}
                    role="dialog"
                    aria-hidden="true">
                    <div>
                        <button
                            type="button"
                            className="button close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeTooltip}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className={`space-y-20 ${style.sellerRow}`}>
                            <p style={{ whiteSpace: "pre-line" }}>{text}</p>
                        </div>
                    </div>
                </div>
            </div>
            <WideButton2
                text={"Cerrar"}
                active={false}
                onClick={closeTooltip}>
            </WideButton2>
        </Popup>
    )
}


export default TextPopUp;